export default [
  // Most recent goes first
  {
    key: 13,
    title: 'WACV2025 Test of Time Award',
    image: '202503-wacv-award-1.jpg'
  },
  {
    key: 12,
    title: 'WACV2025 Test of Time Award',
    image: '202503-wacv-award-2.jpg'
  },
  {
    key: 11,
    title: 'WACV2025 Test of Time Award',
    image: '202503-wacv-award-3.jpg'
  },
  {
    key: 10,
    title: 'VIMS Lab at UD CIS 60th Anniversary',
    image: 'vims_lab_picture.jpg'
  },
  {
    key: 9,
    title: 'With Dr Bajcsy at Franklin Awards Dinner 2024',
    image: '2024-franklin-awards.jpeg'
  },
  {
    key: 8,
    title: 'ARL Project Kickoff. Jan, 2024',
    image: '202401-ARL_UDelKickoff_GroupPicsai.jpeg'
  },
  {
    key: 7,
    title: 'With Yann LeCun at MICCAI 2023',
    image: '2023-miccai-lecun.jpeg'
  },
  {
    key: 6,
    title:
      'UD\'s provost trying out the lab\'s VR. October, 2022',
    image: '6.jpg'
  },
  {
    key: 5,
    title:
      'UD\'s provost visiting our lab for a demo. October, 2022',
    image: '5.JPG'
  },
  {
    key: 4,
    title:
      'At Yi Liu\'s graduation ceremony. June, 2022',
    image: '2-1.jpg'
  },
  {
    key: 3,
    title:
      'At Yi Liu\'s graduation ceremony. June, 2022',
    image: '1-1.jpg'
  },
  {
    key: 2,
    title:
      'Demos in the lab during the CIS alumni event. June, 2022',
    image: '3-1.jpg'
  },
  {
    key: 1,
    title:
      'Current and past VIMS lab members meeting at CVPR. Long Beach, 2019',
    image: '4-1.jpg'
  },
];